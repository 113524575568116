.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.productType {
  width: 30px;
  height: 30px;
}

.App-link {
  color: #61dafb;
}

.main {
  max-width: 1600px;
  margin: auto;
}

.main-type {
  max-width: 1600px;
  margin: auto;
  background-color: unset!important;
}

.last-row-form {
  text-align: right;
}

.headerLogo {
  max-height: 36px!important;
}

.header {
  background-color: #ffffff;
  text-align: center;
}

.spin-center {
  text-align: center;
}

.infobar {
  margin-bottom: 20px;
}

.prefildataplace {
  margin-bottom: unset;
}

@media only screen and (min-width: 1200px) {
video {
  width: 500px;
  height: 500px;
}

.center-camera {
  text-align: center;
  position: relative;
}
}

.button_product_type {
  max-width: 200px;
  height: 100px;
}

.button-product_type_default {
  background-color: #faad14;
  border:1px solid #faad14;
  color: #ffffff;
  max-width: 200px;
  height: 100px;
}

.button-product_type_default:hover {
  background-color: #faad14a3!important;
  border:1px solid #faad14a3!important;
  color: #ffffff!important;
}

.button-product_type_orange {
  background-color: #599b3d;
  border:1px solid #599b3d;
  color: #ffffff;
  max-width: 200px;
  height: 100px;
}

.button-product_type_orange:hover {
  background-color: #71a45c!important;
  border:1px solid #71a45c!important;
  color: #ffffff!important;
}

.button-product_type_used {
  background-color: #362dbb;
  border:1px solid #362dbb;
  color: #ffffff;
  max-width: 200px;
  height: 100px;
}

.button-product_type_used:hover {
  background-color: #554dc3!important;
  border:1px solid #554dc3!important;
  color: #ffffff!important;
}

.button-scan {
  background-color: #faad14;
}

.button-scan:hover {
  background-color: #faad14a3!important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
